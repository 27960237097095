import {useTranslation} from 'next-i18next'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import striptags from 'striptags'
import {FooterMenuLink, MenuLink} from '../../../types/content-types'
import Doormat from './Doormat'
import FooterNavigation from './FooterNavigation'

type Props = {
  footerMenuItems: FooterMenuLink[] | null
  doormatMenuItems: MenuLink[] | null
  socialMenuItems: MenuLink[] | null
}

const Footer: React.FC<Props> = ({
  footerMenuItems,
  doormatMenuItems,
  socialMenuItems,
}: Props) => {
  const currentYear = new Date().getFullYear()
  const footerMenuItemsFull = footerMenuItems?.filter(
    el => el.type === 'group_content--site-group_menu_link-footer',
  ) as FooterMenuLink[]
  const {t} = useTranslation()
  return (
    <footer className="c-footer position-relative">
      <Doormat
        doormatMenuItems={doormatMenuItems}
        socialMenuItems={socialMenuItems}
      />
      <div className="c-footer__container d-flex py-4 text-center text-lg-left">
        <Container fluid>
          <Row className="my-0 flex-column-reverse flex-lg-row">
            <Col sm={{span: 12}} md={{span: 4}} xl={{span: 2}}>
              <span className="c-footer__brand d-flex align-items-center text-center mt-3  mt-lg-0 w-100">
                {striptags(t('footer_copyright'))} {currentYear}
              </span>
            </Col>
            <Col sm={{span: 12}} md={{span: 8}} xl={{span: 10}}>
              <div className="d-flex  justify-content-lg-center  h-100">
                <FooterNavigation footerMenuItems={footerMenuItemsFull} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
