type RichTextProps = {
  html?: string
  extraClass?: string
}

const RichText = ({html, extraClass = ''}: RichTextProps) => {
  if (!html) {
    return null
  }
  return (
    <div
      className={`rich-text ${extraClass}`}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

export default RichText
