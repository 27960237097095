import React from 'react'

type ContentTitles = {
  title?: string
  toptitle?: string
  isToptitleCustomColor: boolean
  titleType?: string
  toptitleColorClass?: string
  extraHeadingClass?: string
}

const ContentTitles = ({
  title,
  toptitle,
  isToptitleCustomColor,
  titleType,
  toptitleColorClass,
  extraHeadingClass = '',
}: ContentTitles) => {
  const CustomHeading = titleType
    ? (`${titleType}` as keyof JSX.IntrinsicElements)
    : 'h4'
  return (
    <>
      {toptitle ? (
        <span
          className={`${isToptitleCustomColor ? '' : 'text-purple'} ${
            toptitleColorClass && isToptitleCustomColor
              ? toptitleColorClass
              : ''
          } d-block text-uppercase font-weight-bolder toptitle`}
        >
          {toptitle}
        </span>
      ) : null}
      {title ? (
        <CustomHeading
          className={`d-block ${extraHeadingClass} ${
            titleType ? titleType : 'h4'
          } mt-2`}
        >
          {title}
        </CustomHeading>
      ) : null}
    </>
  )
}

export default ContentTitles
