import React from 'react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import ConditionalWrapper from './ConditionalWrapper'
import {DrupalLink} from '../../types/content-types'

type Props = {
  link?: DrupalLink
  extraClass?: string
  children: React.ReactNode
  locale?: string
  onClick?: () => void
}

const GenericLink = ({
  link,
  extraClass = '',
  children,
  locale,
  onClick,
}: Props) => {
  const router = useRouter()
  if (!link) {
    return <>{children}</>
  }
  let actualUrl: string | undefined
  if (link.uri?.alias) {
    if (link.uri.bundle === 'homepage') {
      actualUrl = '/'
    } else if (
      router.locale &&
      link.uri.alias.includes(router.locale.toLowerCase())
    ) {
      actualUrl = link.uri.alias.replace(`${router.locale.toLowerCase()}/`, '')
    } else {
      actualUrl = link.uri.alias
    }
  } else if (link.alias) {
    actualUrl = link.alias
  }
  return (
    <ConditionalWrapper
      condition={!!link.uri?.alias || !!link.alias}
      wrapper={(childrenPassed: any) => (
        <Link href={actualUrl ?? link.uri?.uri ?? '/'} locale={locale} passHref>
          {childrenPassed}
        </Link>
      )}
    >
      <a
        href={actualUrl ?? link.uri?.uri ?? '/'}
        className={extraClass ?? ''}
        onClick={onClick}
        target={link.options?.attributes?.target}
        rel={
          link.options?.attributes?.target === '_blank'
            ? 'noopener noreferrer'
            : undefined
        }
      >
        {children}
      </a>
    </ConditionalWrapper>
  )
}

export default GenericLink
