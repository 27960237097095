import React from 'react'
import Nav from 'react-bootstrap/Nav'
import {MenuLink} from '../../../types/content-types'

type Props = {
  socialMenuItems?: MenuLink[]
}

const SocialMediaNav: React.FC<Props> = ({socialMenuItems}: Props) => (
  <Nav className="c-footer__doormat--socialMediaNavWrapper flex-lg-row">
    <span className="c-footer__doormat--navItem c-footer__doormat--navHeader w-100">
      Follow us
    </span>
    <Nav className="c-footer__doormat--socialMediaNav">
      {socialMenuItems
        ?.filter(social => social.enabled)
        .sort((a, b) => a.weight! - b.weight!)
        .map(socialMenuItem => (
          <a
            key={socialMenuItem.id}
            className="pr-3 c-footer__doormat--navItem"
            href={socialMenuItem.link.uri.uri}
            aria-label={socialMenuItem.field_social_type}
            target={socialMenuItem.link.options?.attributes.target}
          >
            <span
              className={`c-footer__doormat--socialMediaNav-icon c-footer__doormat--socialMediaNav-${socialMenuItem.field_social_type}`}
            />
          </a>
        ))}
    </Nav>
  </Nav>
)

export default SocialMediaNav
