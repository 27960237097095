import React from 'react'
import classNames from 'classnames'
import GenericLink from '../../../shared/Link'
import MediaItem from '../../../shared/MediaItem'
import {
  ContentBlockData,
  MediaDocument,
  MediaImage,
} from '../../../../types/paragraphs'
import {Response} from '../../../../types/api-types'
import ContentTitles from '../../../shared/ContentTitles'
import RichText from '../../../shared/RichText'

type Props = {
  data: ContentBlockData
  singleBlock?: boolean
  mediaPlacement: string
  response: Response | null
}

const ContentBlock = ({
  data,
  mediaPlacement,
  singleBlock = false,
  response,
}: Props) => {
  const contentBlockMedia: MediaImage = response?.included?.find(
    el => el.id === data.relationships?.field_media?.data?.id,
  )
  const contentBlockDocuments: MediaDocument[] | undefined =
    data.relationships?.field_document.data.map(document =>
      response?.included?.find(el => el.id === document.id),
    )

  const description = data.attributes.field_text?.processed
  const backgroundColor = data.attributes.field_content_block_background
  const alignment = data.attributes.field_alignment
  const iframe = data.attributes.field_iframe
  const button = data.attributes.field_button
  const buttonStyle = data.attributes.field_button_style

  const sparks = data.attributes.field_sparks
  const btnClass = classNames('btn', {
    'btn-link bg-white': buttonStyle === 'arrow',
    'btn-secondary': buttonStyle === 'secondary',
    'btn--orange':
      !buttonStyle ||
      (buttonStyle === 'default' && !backgroundColor) ||
      backgroundColor === 'none',
    'btn--outline-purple bg-white':
      !buttonStyle ||
      (buttonStyle === 'default' &&
        backgroundColor &&
        backgroundColor !== 'none'),
  })

  return (
    <div
      className={`c-content-block c-content-block--bg-${backgroundColor} c-content-block--align-${alignment} ${
        sparks ? 'c-content-block--sparks' : ''
      } ${singleBlock ? `c-content-block--media-align-${mediaPlacement}` : ''}`}
    >
      {contentBlockMedia && (
        <MediaItem
          response={response}
          item={contentBlockMedia}
          wrapperClass={
            contentBlockMedia.type === 'media--video'
              ? 'c-content-block__video'
              : 'c-content-block__image'
          }
        />
      )}
      <div className="c-content-block__text-wrapper">
        <ContentTitles
          title={data.attributes.field_title}
          toptitle={data.attributes.field_toptitle}
          isToptitleCustomColor={true}
          titleType={data.attributes.field_heading_type_content}
        />

        {description && (
          <RichText
            extraClass="c-content-block__description"
            html={description}
          />
        )}
        {contentBlockDocuments?.length !== 0 ||
        contentBlockDocuments[0] !== undefined ? (
          <ul
            className={`${
              backgroundColor !== 'orange' ? 'text-orange' : 'text-white'
            } list-square c-content-block__documentList`}
          >
            {contentBlockDocuments?.map(documentData => {
              if (!documentData) {
                return null
              }
              return (
                <MediaItem
                  response={response}
                  item={documentData}
                  key={documentData.id}
                />
              )
            })}
          </ul>
        ) : null}

        {iframe && (
          <div
            className="c-content-block__iframe rich-text"
            dangerouslySetInnerHTML={{
              __html: iframe,
            }}
          />
        )}
        {button && (
          <GenericLink extraClass={btnClass} link={button}>
            {button.title}
          </GenericLink>
        )}
      </div>
    </div>
  )
}

export default ContentBlock
