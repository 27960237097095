import React, {useState} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {CSSTransition} from 'react-transition-group'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import GenericLink from '../../shared/Link'
import FunctionalNavigation from './FunctionalNavigation'
import NavigationHighlight from './NavigationHighlight'
import ConditionalWrapper from '../../shared/ConditionalWrapper'
import logo from '../../../public/sdworx_logo.svg'
import {MenuLink} from '../../../types/content-types'

type Props = {
  mainMenuItems: MenuLink[] | null
  functionalNavItems?: MenuLink[] | null
  topNavigationMenuItems?: MenuLink[] | null
  expanded: boolean
  // eslint-disable-next-line no-unused-vars
  setExpanded: (expandedState: boolean) => void
  // eslint-disable-next-line no-unused-vars
  setShadow?: (val: boolean) => void
}

const MainNavigation = ({
  mainMenuItems,
  functionalNavItems,
  topNavigationMenuItems,
  expanded,
  setExpanded,
  setShadow,
}: Props) => {
  const [activeMenu, setActiveMenu] = useState('main')
  const [activeSubNav, setActiveSubNav] = useState('')
  const router = useRouter()

  React.useEffect(() => {
    const handleRouteChange = () => {
      setActiveSubNav('')
      setActiveMenu('main')
      setExpanded(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, setExpanded])
  /*   const functionalNavItemsFull = functionalNavItems?.filter(
    el => el.type === 'menu_link_content--functional',
  ) as MenuLink[] | undefined */
  const topLevelMainMenuItems = mainMenuItems?.filter(el => !el.parent)

  const topNavigationMenuItemsFull = topNavigationMenuItems?.filter(
    el => el.type === 'menu_link_content--top-navigation',
  ) as MenuLink[]

  const handleMenuItemClick = (menuItem: MenuLink | null = null) => {
    if (menuItem && menuItem.children) {
      setActiveMenu(menuItem.id)
    } else {
      setActiveMenu('main')
      setExpanded(false)
    }
  }

  const handleToggleClick = () => {
    setActiveMenu('main')
    setExpanded(!expanded)
  }

  const handleMenuItemHoverOn = (item: any[] | undefined) => {
    if (item && item.length > 0) {
      setShadow?.(true)
    } else {
      setShadow?.(false)
    }
  }

  const renderNavigationItem = (
    navigationItem: MenuLink,
    withWrapper: boolean = false,
    thirdStage: boolean = false,
  ) => {
    const enabledChildren = mainMenuItems?.filter(el =>
      el?.parent?.includes(navigationItem.id),
    )

    return (
      <ConditionalWrapper
        condition={withWrapper}
        wrapper={(childrenPassed: any) => (
          <div className="px-4 py-2 d-flex justify-content-between w-full">
            {childrenPassed}
          </div>
        )}
        key={navigationItem.id}
      >
        <Nav.Item
          className={
            enabledChildren && enabledChildren.length > 0
              ? 'hasChildren'
              : 'border-0 hasNoChildren'
          }
          key={navigationItem.id}
          onClick={() => {
            handleMenuItemClick(navigationItem)
          }}
        >
          <GenericLink
            extraClass={`ma w-100 ${
              enabledChildren?.length === 0 && thirdStage
                ? 'font-weight-light grandchild'
                : 'font-weight-bold'
            }`}
            link={navigationItem.link}
          >
            {navigationItem.title}
          </GenericLink>
        </Nav.Item>
      </ConditionalWrapper>
    )
  }

  const renderMainNavigation = (menuItem: MenuLink) => {
    const enabledChildren = mainMenuItems?.filter(el =>
      el.parent?.includes(menuItem.id),
    )

    const wrapGroup = !enabledChildren?.some(child =>
      mainMenuItems?.some(el => el.parent?.includes(child.id)),
    )
    return (
      <Nav
        className={`c-navbar__subnav ${
          menuItem.id === activeSubNav ? 'active' : ''
        }`}
        key={menuItem.id}
      >
        <div className="d-flex flex-row py-4">
          <ConditionalWrapper
            condition={wrapGroup}
            wrapper={(childrenPassed: any) => (
              <div className="d-flex flex-column flex-wrap justify-content-start align-items-start">
                {childrenPassed}
              </div>
            )}
          >
            {enabledChildren
              ?.sort((a, b) => a.weight! - b.weight!)
              ?.map(firstLevel => {
                const enabledGrandChildren = mainMenuItems?.filter(el =>
                  el.parent?.includes(firstLevel.id),
                )
                return (
                  <React.Fragment key={firstLevel.id}>
                    <div
                      className={`c-navbar__subnav-group ${
                        enabledChildren.length === 0 ? `d-flex` : ''
                      }`}
                    >
                      {renderNavigationItem(firstLevel)}

                      {enabledGrandChildren
                        ?.sort((a, b) => a.weight! - b.weight!)
                        .map(secondLevel =>
                          renderNavigationItem(secondLevel, false, true),
                        )}
                    </div>
                  </React.Fragment>
                )
              })}
          </ConditionalWrapper>
        </div>
        <NavigationHighlight item={menuItem} />
      </Nav>
    )
  }

  const renderMobileNavigation = (
    childrenForMobile: MenuLink,
    parentId: string,
  ) => {
    const enabledChildren = mainMenuItems?.filter(el =>
      el.parent?.includes(childrenForMobile.id),
    )

    return (
      <CSSTransition
        in={activeMenu === childrenForMobile.id}
        timeout={250}
        classNames="c-navbar__menu-slide"
        unmountOnExit
        key={`${parentId}-${childrenForMobile.id}`}
      >
        <div>
          <Nav className="c-navbar__subnav-mobile">
            {childrenForMobile && (
              <Nav.Item className="c-navbar__subnav-mobile-title">
                <button
                  onClick={() => setActiveMenu(parentId)}
                  aria-label="Back to parent"
                  type="button"
                />
                {childrenForMobile.title}
              </Nav.Item>
            )}
            <div className="c-navbar__subnav-mobile-group">
              {enabledChildren
                ?.sort((a, b) => a.weight! - b.weight!)
                .map(child => {
                  const enabledGrandChildren = mainMenuItems?.filter(el =>
                    el.parent?.includes(child.id),
                  )
                  return (
                    <React.Fragment key={child.id}>
                      <div
                        className={`c-navbar__subnav-group ${
                          enabledChildren.length === 0 ? `d-flex` : ''
                        }`}
                      >
                        {renderNavigationItem(child, true)}
                        <div className="d-block w-100">
                          {enabledGrandChildren
                            ?.sort((a, b) => a.weight! - b.weight!)
                            .map(secondLevel =>
                              renderNavigationItem(secondLevel, true, true),
                            )}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
            </div>
          </Nav>

          <NavigationHighlight item={childrenForMobile} />
        </div>
      </CSSTransition>
    )
  }

  return (
    <Navbar
      expand="lg"
      variant="light"
      bg="white"
      className="c-navbar py-2"
      collapseOnSelect
      onSelect={() => {
        setActiveMenu('main')
      }}
      expanded={expanded}
    >
      <Link href="/" passHref>
        <Navbar.Brand className="ma">
          <Image width={281} height={48} alt="logo" src={logo} priority />
        </Navbar.Brand>
      </Link>

      <Navbar.Toggle
        aria-controls="navbar-nav"
        className="close-btn"
        onClick={() => handleToggleClick()}
      >
        <></>
      </Navbar.Toggle>
      <Navbar.Collapse id="navbar-nav ">
        <Link href="/" passHref>
          <Navbar.Brand className="ma d-lg-none position-absolute pt-2 mt-1 pl-3">
            <Image width={281} height={48} alt="logo" src={logo} priority />
          </Navbar.Brand>
        </Link>
        <div className="c-navbar__nav-desktop">
          <Nav className="ml-auto h-100 align-items-center">
            {topLevelMainMenuItems
              ?.sort((a, b) => a.weight! - b.weight!)
              .map(menuItem => {
                const enabledChildren = mainMenuItems?.filter(el =>
                  el.parent?.includes(menuItem.id),
                )
                return (
                  <Nav.Item
                    className={`${
                      enabledChildren && enabledChildren.length! > 0
                        ? 'hasChildren'
                        : ''
                    }
                  `}
                    key={menuItem.id}
                    onMouseEnter={() => {
                      setActiveSubNav(menuItem.id)
                      handleMenuItemHoverOn(enabledChildren)
                    }}
                    onMouseLeave={() => {
                      setActiveSubNav('')
                      setShadow?.(false)
                    }}
                    onFocus={() => setActiveSubNav(menuItem.id)}
                    onBlur={() => setActiveSubNav('')}
                  >
                    <GenericLink
                      extraClass={`ma ${
                        menuItem.id === activeSubNav ? 'active' : ''
                      }`}
                      link={menuItem.link}
                    >
                      {menuItem.title}
                    </GenericLink>
                    {enabledChildren && enabledChildren.length! > 0 && (
                      <button
                        onClick={() => {
                          setActiveMenu(menuItem.id)
                        }}
                        type="button"
                        className="c-navbar__nav-mobile--subNavBtn text-center border-0 d-lg-none"
                        aria-label="Open subnavigation"
                      >
                        +
                      </button>
                    )}
                    {enabledChildren &&
                      enabledChildren.length > 0 &&
                      renderMainNavigation(menuItem)}
                  </Nav.Item>
                )
              })}
          </Nav>
          {functionalNavItems && (
            <FunctionalNavigation functionalNavItems={functionalNavItems} />
          )}
        </div>
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames="c-navbar__menu-slide"
          unmountOnExit
        >
          <div className="c-navbar__nav-mobile ">
            <Nav className="mr-auto h-100 align-items-center px-4">
              {topLevelMainMenuItems
                ?.sort((a, b) => a.weight! - b.weight!)
                .map(menuItem => {
                  const enabledChildren = mainMenuItems?.filter(el =>
                    el.parent?.includes(menuItem.id),
                  )
                  return (
                    <Nav.Item
                      className={
                        enabledChildren && enabledChildren.length! > 0
                          ? 'hasChildren'
                          : ''
                      }
                      key={`csst-${menuItem.id}`}
                    >
                      <div className="c-navbar__nav-mobile__innerWrapper d-flex justify-content-between w-full">
                        <GenericLink extraClass="ma" link={menuItem.link}>
                          {menuItem.title}
                        </GenericLink>
                        {enabledChildren && enabledChildren.length > 0 && (
                          <button
                            onClick={() => {
                              setActiveMenu(menuItem.id)
                            }}
                            type="button"
                            className="c-navbar__nav-mobile--subNavBtn"
                            aria-label="Open subnavigation"
                          >
                            +
                          </button>
                        )}
                      </div>
                      {/* {enabledChildren && renderMainNavigation(menuItem)} */}
                    </Nav.Item>
                  )
                })}
            </Nav>
            {functionalNavItems && (
              <FunctionalNavigation functionalNavItems={functionalNavItems} />
            )}
            <div className="c-navbar__subnav-mobile__top-nav-mobile px-4 py-2 mt-3">
              {topNavigationMenuItemsFull?.length > 0 ? (
                <>
                  {topNavigationMenuItemsFull
                    ?.filter(el => el.enabled)
                    .sort((a, b) => a.weight! - b.weight!)
                    .map(item => {
                      return (
                        <GenericLink
                          extraClass={`ctn-audience-link my-1`}
                          link={item.link}
                          key={item.id}
                        >
                          {item.title}
                        </GenericLink>
                      )
                    })}
                </>
              ) : null}
            </div>
          </div>
        </CSSTransition>
        {topLevelMainMenuItems
          ?.sort((a, b) => a.weight! - b.weight!)
          .map(child => {
            const enabledChildren = mainMenuItems?.filter(el =>
              el.parent?.includes(child.id),
            )
            if (enabledChildren && enabledChildren.length > 0) {
              return renderMobileNavigation(child, 'main')
            }
            return null
          })}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNavigation
