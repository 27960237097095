import React from 'react'
import {DrupalLink, MenuLink} from '../../../types/content-types'
import MainNavigation from './MainNavigation'
import TopNavigation from './TopNavigation'

type Props = {
  mainMenuItems: MenuLink[] | null
  functionalNavItems: MenuLink[] | null
  urlTranslations?: DrupalLink[] | null
  siteSwitchMenuItems: MenuLink[] | null
  topNavigationMenuItems: MenuLink[] | null
}

const Header = ({
  mainMenuItems,
  functionalNavItems,
  urlTranslations,
  topNavigationMenuItems,
  siteSwitchMenuItems,
}: Props) => {
  const headerRef = React.useRef<HTMLElement>(null)
  const [expanded, setExpanded] = React.useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [shadow, setShadow] = React.useState(false)
  React.useEffect(() => {
    const sticky = headerRef.current?.offsetTop
    if (!expanded) {
      if (window.pageYOffset > sticky!) {
        if (!headerRef.current?.classList.contains('fixed-top')) {
          headerRef.current?.classList.add('fixed-top')
        }
      } else if (headerRef.current?.classList.contains('fixed-top')) {
        headerRef.current?.classList.remove('fixed-top')
      }
    }
    const handleScroll = () => {
      if (!expanded) {
        if (window.pageYOffset > sticky!) {
          if (!headerRef.current?.classList.contains('fixed-top')) {
            headerRef.current?.classList.add('fixed-top')
          }
        } else if (headerRef.current?.classList.contains('fixed-top')) {
          headerRef.current?.classList.remove('fixed-top')
        }
      }
    }
    if (headerRef.current) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [headerRef, expanded])

  React.useEffect(() => {
    if (wrapperRef.current) {
      if (!shadow) {
        if (wrapperRef.current?.classList.contains('active')) {
          wrapperRef.current?.classList.remove('active')
        }
      } else {
        wrapperRef.current?.classList.add('active')
      }
    }
  }, [shadow])
  return (
    <div className="c-navbar__overlay" ref={wrapperRef}>
      <TopNavigation
        siteSwitchMenuItems={siteSwitchMenuItems}
        urlTranslations={urlTranslations}
        topNavigationMenuItems={topNavigationMenuItems}
      />
      <header className={expanded ? 'fixed-top' : ''} ref={headerRef}>
        <MainNavigation
          mainMenuItems={mainMenuItems}
          functionalNavItems={functionalNavItems}
          topNavigationMenuItems={topNavigationMenuItems}
          expanded={expanded}
          setExpanded={setExpanded}
          setShadow={(val: boolean) => setShadow(val)}
        />
      </header>
    </div>
  )
}

export default Header
