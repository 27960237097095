import {useRouter} from 'next/router'
import React, {useState} from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import {v4 as uuidv4} from 'uuid'
import GenericLink from '../../shared/Link'
import {DrupalLink, MenuLink} from '../../../types/content-types'

type Props = {
  siteSwitchMenuItems?: MenuLink[] | null
  urlTranslations?: DrupalLink[] | null
  topNavigationMenuItems?: MenuLink[] | null
}

const TopNavigation = ({
  siteSwitchMenuItems,
  urlTranslations,
  topNavigationMenuItems,
}: Props) => {
  const [activeState, setActiveState] = useState(0)
  const router = useRouter()
  const siteSwitchMenuItemsFull = siteSwitchMenuItems?.filter(
    el => el.type === 'menu_link_content--site-switch',
  ) as MenuLink[]
  const topNavigationMenuItemsFull = topNavigationMenuItems?.filter(
    el => el.type === 'menu_link_content--top-navigation',
  ) as MenuLink[]

  const langSwitcherConditional = urlTranslations && urlTranslations.length > 0
  return (
    <div className="c-top-navigation position-relative  justify-content-between align-items-center d-flex">
      <div className="ctn-audience-wrapper d-flex ">
        {siteSwitchMenuItemsFull
          ?.filter(el => el.enabled)
          .sort((a, b) => a.weight! - b.weight!)
          .map((item, index) => {
            const id = uuidv4()
            return (
              <GenericLink
                extraClass={`ctn-audience-link ${
                  index === activeState
                    ? 'font-weight-bold position-relative d-inline-block active'
                    : 'font-weight-normal'
                }`}
                link={item.link}
                key={id}
                onClick={() => setActiveState(index)}
              >
                {item.title}
              </GenericLink>
            )
          })}
      </div>

      <div className="ctn-top-bar-wrapper d-flex justify-content-between align-items-center">
        {topNavigationMenuItemsFull?.length > 0 ? (
          <div className="d-none d-lg-block">
            {topNavigationMenuItemsFull
              ?.filter(el => el.enabled)
              .sort((a, b) => a.weight! - b.weight!)
              .map((item, index) => {
                const id = uuidv4()
                return (
                  <GenericLink
                    extraClass={`ctn-audience-link font-weight-normal ${
                      index === topNavigationMenuItemsFull?.length - 1
                        ? 'mr-0'
                        : ''
                    }`}
                    link={item.link}
                    key={id}
                    onClick={() => setActiveState(index)}
                  >
                    {item.title}
                  </GenericLink>
                )
              })}
          </div>
        ) : null}
        {langSwitcherConditional ? (
          <DropdownButton
            className="ctn-lang-switch d-flex"
            id="language-dropdown"
            title={router.locale?.split('-')[0].toUpperCase()}
          >
            {urlTranslations ? (
              urlTranslations.map(translation => (
                <Dropdown.Item
                  key={translation.langcode}
                  href={`/${translation.langcode}${
                    router.asPath === '/' ? '' : translation.alias
                  }`}
                >
                  {translation.langcode?.split('-')[0].toUpperCase()}
                </Dropdown.Item>
              ))
            ) : (
              <>
                {router.locales
                  ?.filter(locale => router.locale !== locale)
                  .map(locale => (
                    <Dropdown.Item key={locale} href={`/${locale}`}>
                      {locale.toUpperCase()}
                    </Dropdown.Item>
                  ))}
              </>
            )}
          </DropdownButton>
        ) : null}
      </div>
    </div>
  )
}

export default TopNavigation
