import React from 'react'
import dynamic from 'next/dynamic'
import ContentBlockSection from './ContentBlockSection'
import InThePictureSection from './InThePicture'
import {Paragraph} from '../../types/paragraphs'
import {Response} from '../../types/api-types'
import {SearchResponse} from '@algolia/client-search'
import {TaxonomyTerm} from '../../types/content-types'

const Text = dynamic(() => import('./Text'))
const TextAndMedia = dynamic(() => import('./TextAndMedia'))
const TextAndContact = dynamic(() => import('./TextAndContact'))
const Heading = dynamic(() => import('./Heading'))
const ArticleContentBlock = dynamic(() => import('./ArticleContentBlock'))
const PersonList = dynamic(() => import('./PersonList'))
const Quote = dynamic(() => import('./Quote'))
const DoubleQuote = dynamic(() => import('./DoubleQuote'))
const LinkBlock = dynamic(() => import('./LinkBlock'))
const TeaserList = dynamic(() => import('./TeaserList'))
const Spotlight = dynamic(() => import('./Spotlight'))
const DomainSlider = dynamic(() => import('./DomainSlider/'))
const Expert = dynamic(() => import('./Expert'))
const News = dynamic(() => import('./News/News'))
const Vision = dynamic(() => import('./Vision'))
const NewsletterSubscription = dynamic(
  () => import('./NewsletterSubscription/index'),
)
const CandidateOverview = dynamic(() => import('./CandidateOverview/index'))

const paragraphs: {
  [key: string]: () => React.FC<any> | React.ComponentType<any>
} = {
  'paragraph--text': () => Text,
  'paragraph--text_and_media': () => TextAndMedia,
  'paragraph--text_and_contact': () => TextAndContact,
  'paragraph--content_block_section': () => ContentBlockSection,
  'paragraph--inthepicture': () => InThePictureSection,
  'paragraph--heading': () => Heading,
  'paragraph--article_content_block': () => ArticleContentBlock,
  'paragraph--person_list': () => PersonList,
  'paragraph--link_block': () => LinkBlock,
  'paragraph--quote': () => Quote,
  'paragraph--quote_quote': () => DoubleQuote,
  'paragraph--teaser_list': () => TeaserList,
  'paragraph--spotlight': () => Spotlight,
  'paragraph--domain_slider': () => DomainSlider,
  'paragraph--expert_block': () => Expert,
  'paragraph--news_block': () => News,
  'paragraph--vision_block': () => Vision,
  'paragraph--newsletter_subscription_block': () => NewsletterSubscription,
  'paragraph--candidate_overview': () => CandidateOverview,
}

type Props = {
  paragraph: Paragraph
  response: Response | null
  blockNewsResourcesAlgolia?: SearchResponse | null
  country: string
  expertiseDomains?: TaxonomyTerm[] | null
  regions?: TaxonomyTerm[] | null
  functionsLevel?: TaxonomyTerm[] | null
  languages?: TaxonomyTerm[] | null
}

const ParagraphMapper = ({
  paragraph,
  response,
  blockNewsResourcesAlgolia,
  country,
  expertiseDomains,
  regions,
  functionsLevel,
  languages,
}: Props) => {
  if (Object.prototype.hasOwnProperty.call(paragraphs, paragraph.type)) {
    const ParagraphComponent = paragraphs[paragraph.type]()
    return (
      <ParagraphComponent
        paragraph={paragraph}
        response={response}
        blockNewsResourcesAlgolia={blockNewsResourcesAlgolia}
        country={country}
        expertiseDomains={expertiseDomains}
        regions={regions}
        functionsLevel={functionsLevel}
        languages={languages}
      />
    )
  }
  return null
}

export default ParagraphMapper
