import {useCallback, ReactNode} from 'react'
import Head from 'next/head'
import Footer from './footer/Footer'
import Header from './header/Header'
import {FooterMenuLink, MenuLink, Node} from '../../types/content-types'
import {useRouter} from 'next/router'

type Props = {
  children?: ReactNode
  mainMenuItems: MenuLink[] | null
  functionalNavItems: MenuLink[] | null
  doormatMenuItems: MenuLink[] | null
  socialMenuItems: MenuLink[] | null
  footerMenuItems: FooterMenuLink[] | null
  page: Node | null
  siteSwitchMenuItems: MenuLink[] | null
  topNavigationMenuItems: MenuLink[] | null
}

const Layout = ({
  children,
  mainMenuItems,
  functionalNavItems,
  doormatMenuItems,
  socialMenuItems,
  footerMenuItems,
  siteSwitchMenuItems,
  topNavigationMenuItems,
  page,
}: Props) => {
  const router = useRouter()

  const canonical = page?.attributes?.metatag_normalized?.find(
    metatag => metatag.attributes?.rel === 'canonical',
  )
  const metaTitle = page?.attributes?.metatag_normalized?.find(
    metatag => metatag.attributes?.name === 'title',
  )
  const metaDescription = page?.attributes?.metatag_normalized?.find(
    metatag => metatag.attributes?.name === 'description',
  )
  const ogTitle = page?.attributes?.metatag_normalized?.find(
    metatag => metatag.attributes?.property === 'og:title',
  )
  const ogDescription = page?.attributes?.metatag_normalized?.find(
    metatag => metatag.attributes?.property === 'og:description',
  )
  const ogImage = page?.attributes?.metatag_normalized?.find(
    metatag => metatag.attributes?.property === 'og:image',
  )
  const hrefLang = page?.attributes?.metatag_normalized?.filter(
    metatag => !!metatag.attributes.hreflang,
  )
  const robots = page?.attributes?.metatag_normalized?.find(
    metatag => metatag.attributes?.name === 'robots',
  )

  const renderFbVerificationMetaTag = useCallback(() => {
    const isHomePage = page?.type === 'node--homepage'
    let content = null
    const currentLocale = router.locale?.toLowerCase?.()

    if (!isHomePage) return null

    switch (currentLocale) {
      case 'nl-be':
        content = 'j22kjzkkyoycsg152wzlvpq45ddu24'
        break
      case 'nl-nl':
        content = 'ik80srh8n4clj2r961rtx5kwu2i9ia'
        break
      default:
        return null
    }

    return <meta name="facebook-domain-verification" content={content} />
  }, [page?.type, router.locale])

  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href={`/favicon.ico`} />
        {canonical ? (
          <link
            rel={canonical.attributes.rel}
            href={
              page?.type === 'node--homepage'
                ? `${
                    process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL
                  }/${router.locale?.toLowerCase()}`
                : `${
                    process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL
                  }/${router.locale?.toLowerCase()}${router.asPath}`
            }
          />
        ) : null}
        {metaTitle ? <title>{metaTitle.attributes.content}</title> : null}
        {ogTitle ? (
          <meta
            content={ogTitle.attributes.content}
            property={ogTitle.attributes.property}
          />
        ) : null}
        {ogImage ? (
          <meta
            content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${ogImage.attributes.content}`}
            property="og:image"
          />
        ) : null}
        {metaDescription ? (
          <meta
            content={metaDescription.attributes.content}
            name={metaDescription.attributes.name}
          />
        ) : null}
        {ogDescription ? (
          <meta
            content={ogDescription.attributes.content}
            property={ogDescription.attributes.property}
          />
        ) : null}
        {renderFbVerificationMetaTag()}
        {hrefLang && hrefLang.length > 0 ? (
          <>
            {hrefLang
              .filter(el => el.attributes.hreflang !== 'x-default')
              .map(el => (
                // eslint-disable-next-line react/jsx-key
                <link
                  hrefLang={el.attributes.hreflang}
                  rel={el.attributes.rel}
                  href={
                    page?.type === 'node--homepage'
                      ? `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${
                          el.attributes.href?.split('/')[1]
                        }`
                      : `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${el.attributes.href}`
                  }
                />
              ))}
          </>
        ) : null}
        {robots ? (
          <meta name="robots" content={robots.attributes.content} />
        ) : null}
      </Head>
      <Header
        mainMenuItems={mainMenuItems}
        functionalNavItems={
          functionalNavItems?.length !== 0 ? functionalNavItems : null
        }
        siteSwitchMenuItems={
          page?.type === 'application-form' ? null : siteSwitchMenuItems
        }
        topNavigationMenuItems={topNavigationMenuItems}
        urlTranslations={
          page?.type !== 'node--vacancy' &&
          page?.type !== 'site-group_node-office' &&
          page?.type !== 'node-office'
            ? page?.attributes?.path_translations
            : null
        }
      />

      <main>{children}</main>
      <Footer
        footerMenuItems={footerMenuItems}
        doormatMenuItems={doormatMenuItems}
        socialMenuItems={socialMenuItems}
      />
    </div>
  )
}

export default Layout
