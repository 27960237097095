import {useTranslation} from 'next-i18next'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import GenericLink from '../../shared/Link'
import SocialMediaNav from './SocialMediaNav'
import {MenuLink} from '../../../types/content-types'
import Image from 'next/image'
import logo from '../../../public/sdworx_logo.svg'
import Link from 'next/link'
import {Navbar} from 'react-bootstrap'

type Props = {
  doormatMenuItems: MenuLink[] | null
  socialMenuItems: MenuLink[] | null
}

const Doormat: React.FC<Props> = ({
  doormatMenuItems,
  socialMenuItems,
}: Props) => {
  const doormatMenuItemsFull = doormatMenuItems?.filter(
    el => el.type === 'menu_link_content--doormat',
  ) as MenuLink[] | undefined
  const doormatMenuItemsFiltered = doormatMenuItemsFull?.filter(
    el => el.enabled,
  )
  const topLevelDoormatMenuItems = doormatMenuItemsFiltered?.filter(
    el => !el.parent,
  )
  const socialMenuItemsFull = socialMenuItems?.filter(
    el => el.type === 'menu_link_content--social',
  ) as MenuLink[] | undefined
  const {t} = useTranslation()
  return (
    <div className="c-footer__doormat position-relative">
      <Container fluid>
        <Row>
          <Col>
            <Link href="/" prefetch={false} passHref>
              <Navbar.Brand className="ma c-footer__doormat__logo mb-3">
                <Image width={235} height={40} alt="logo" src={logo} priority />
              </Navbar.Brand>
            </Link>
          </Col>
        </Row>
        <Row className="c-footer__doormat--navWrapper flex-md-row flex-column">
          {topLevelDoormatMenuItems
            ?.sort((a, b) => a.weight! - b.weight!)
            .map((menuItem, index) => {
              const enabledChildren = doormatMenuItemsFiltered?.filter(el =>
                el.parent?.includes(menuItem.id),
              )
              return (
                <Col key={menuItem.id}>
                  {enabledChildren
                    ?.sort((a, b) => a.weight! - b.weight!)
                    .map(navHeaderItem => {
                      const enabledGrandChildren =
                        doormatMenuItemsFiltered?.filter(el =>
                          el.parent?.includes(navHeaderItem.id),
                        )
                      return (
                        <div className="mb-3" key={navHeaderItem.id}>
                          <GenericLink
                            extraClass="c-footer__doormat--navItem c-footer__doormat--navHeader"
                            link={navHeaderItem.link}
                          >
                            {navHeaderItem.title}
                          </GenericLink>
                          <div className="c-footer__doormat--navItem--childrenWrapper  flex-column flex-wrap ">
                            {enabledGrandChildren
                              ?.sort((a, b) => a.weight! - b.weight!)
                              .map(navItem => (
                                <GenericLink
                                  extraClass="c-footer__doormat--navItem d-block p-0 mr-3"
                                  key={navItem.id}
                                  link={navItem.link}
                                >
                                  {navItem.title}
                                </GenericLink>
                              ))}
                          </div>
                        </div>
                      )
                    })}
                  {index === 2 ? (
                    <div className="c-footer__doormat__navigation-approval mt-5">
                      <span
                        className="text-uppercase font-weight-bold"
                        dangerouslySetInnerHTML={{
                          __html: t('navigation_approval_title'),
                        }}
                      ></span>
                      <div
                        className="text-white mt-2"
                        dangerouslySetInnerHTML={{
                          __html: t('navigation_approval_numbers'),
                        }}
                      ></div>
                    </div>
                  ) : null}
                </Col>
              )
            })}

          <Col>
            <SocialMediaNav socialMenuItems={socialMenuItemsFull} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Doormat
