import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ReferenceItem from './ReferenceItem'
import {
  InThePictureSectionParagraph,
  Paragraph,
  Reference,
} from '../../../types/paragraphs'
import {Response} from '../../../types/api-types'

type Props = {
  paragraph: Paragraph
  response: Response | null
}

const InThePictureSection = ({paragraph, response}: Props) => {
  const paragraphFull = response?.included?.find(
    el => el.id === paragraph.id,
  ) as InThePictureSectionParagraph | undefined
  if (!paragraphFull) {
    return null
  }
  const {field_title: title, field_layout: layout} = paragraphFull.attributes
  const referenceItems = response?.included?.filter(el =>
    paragraphFull.relationships.field_reference_item?.data?.find(
      cb => cb.id === el.id,
    ),
  ) as Reference[]

  return (
    <section
      className={`position-relative px-0 c-in-the-picture-section--layout-${layout} section--bg-splitGreyLeft section--sparks`}
    >
      <Container fluid>
        {title && <h2>{title}</h2>}
        <Row>
          {referenceItems.map(referenceItem => (
            <Col
              key={referenceItem.id}
              lg={{
                span: layout === 'horizontal' ? 4 : 8,
                offset: layout === 'horizontal' ? 0 : 2,
              }}
            >
              <ReferenceItem response={response} data={referenceItem} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default InThePictureSection
