import React from 'react'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import {MenuLink} from '../../../types/content-types'
import GenericLink from '../../shared/Link'

type Props = {
  functionalNavItems?: MenuLink[]
}

const FunctionalNavigation = ({functionalNavItems}: Props) => (
  <Nav className="c-navbar__functional-nav  d-flex px-3 mt-3 p-lg-0 mt-lg-0 h-100 align-items-center">
    {functionalNavItems
      ?.filter(item => item.field_functional_type === 'login' && item.enabled)
      .map(item => (
        <Nav.Item className="c-navbar__functional-nav-login" key={item.id}>
          <GenericLink extraClass="ma" link={item.link}>
            {item.title}
          </GenericLink>
        </Nav.Item>
      ))}
    {functionalNavItems
      ?.filter(item => item.field_functional_type === 'button' && item.enabled)
      .sort((a, b) => a.weight! - b.weight!)
      .map(item => (
        <Button
          className="btn--orange c-navbar__functional-nav-button--orange border-0"
          key={item.id}
        >
          <GenericLink extraClass="ma text-white" link={item.link}>
            {item.title}
          </GenericLink>
        </Button>
      ))}
  </Nav>
)

export default FunctionalNavigation
